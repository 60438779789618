import { SelectOption } from '../../components/common/NativeSelect';
import {
  GlobalAggregations,
  MarketplaceProduct,
  QueryAggregations,
} from '../../components/pages/Marketplace/types';
import { FilterCommissionStatus } from '../../contexts/common/types';

export enum MarketplaceCommissionType {
  fixed = 'fixed',
  percentage = 'percentage',
}

export enum MarketplaceCommissionTarget {
  PROGRAMME = 'programme',
  CAMPAIGN = 'campaign',
}

export interface MarketplaceProgrammeCommission {
  type: MarketplaceCommissionType;
  new: number;
  returning: number;
  target: MarketplaceCommissionTarget;
  category?: string;
}
export interface MarketplaceProgramme {
  status?: string;
  urls: string[];
  id: string;
  brand: string;
  title: string;
  created_by_admin: string;
  commissions: {
    fallback: MarketplaceProgrammeCommission;
    categories: MarketplaceProgrammeCommission[];
  };
  default_currency: string;
  description: string;
  banner_image_id: string | null;
  logo_image_id: string | null;
  thumbnail_image_id: string | null;
  programme_type: string;
}

export interface FetchingMarketplaceState {
  retailers: boolean;
  productTrackingLinks: boolean;
  interests: boolean;
  createTrackingLinks: boolean;
  waitlist: boolean;
  favouriteProducts: boolean;
  postTrackingLinkFeedback: boolean;
  getTrackingLinkFeedbackForm: boolean;
  products: boolean;
  marketplaceTerms: boolean;
  linkPerformance: boolean;
  conversionPerformance: boolean;
}

export interface WaitlistStatusApiPayload {
  has_accepted: boolean;
}

export interface WaitlistStatusApiResponse {
  has_accepted: boolean;
  prompt_user: boolean;
}

export interface ProductFilters {
  brands: string[];
  industries: string[];
  partners: string[];
  categories: string[];
}

export interface LinkPerformanceAPI {
  id: string;
  created_at: string;
  commission: number;
  title: string;
  product_image: string;
  retailer: string;
  brand: string;
  shortened_tracking_link: string;
  clicks: number;
  sales: number;
  conversion_rate: number;
  currency: string;
}

export interface LinkPerformance {
  id: string;
  createdAt: string;
  commission: number;
  title: string;
  productImage: string;
  retailer: string;
  brand: string;
  shortenedTrackingLink: string;
  clicks: number;
  sales: number;
  conversionRate: number;
  currency: string;
}

export interface LinkPerformanceResponse {
  links: LinkPerformanceAPI[];
  pagination: {
    total_count: number;
    limit: number;
    page: number;
  };
}

export type SortOptionKey =
  | 'latestFirst'
  | 'oldestFirst'
  | 'clicks'
  | 'sales'
  | 'commission'
  | 'conversionRate';

export interface LinkPerformancePaginated {
  links: Map<number, LinkPerformance[] | null>;
  pagination: {
    totalCount: number;
    limit: number;
    page: number;
    sort: SortOptionKey;
  };
}

export const pageSortOptions: SelectOption[] = [
  { key: 'Latest First', value: 'latestFirst' },
  { key: 'Oldest First', value: 'oldestFirst' },
  { key: 'Clicks', value: 'clicks' },
  { key: 'Sales', value: 'sales' },
  { key: 'Commission', value: 'commission' },
  { key: 'Conversion Rate', value: 'conversionRate' },
];

export interface ConversionPerformanceSummary {
  commissions: { amount: number; currency: string }[];
  sales: number;
  linkClicks: number;
  conversionRate: number;
}

export interface ConversionPerformanceSummaryAPI {
  commissions: { amount: number; currency: string }[];
  sales: number;
  link_clicks: number;
  conversion_rate: number;
}

export interface ConversionPerformanceOrdersAPI {
  id: string;
  retailer: string;
  created_at: string;
  value: number;
  commission: number;
  status: FilterCommissionStatus;
  currency: string;
  brand: string;
  title: string;
  product_image: string;
  shortened_tracking_link: string;
  clicks: number;
  link_commission: number;
  conversion_rate: number;
  sales: number;
}

export interface ConversionPerformanceOrders {
  id: string;
  retailer: string;
  createdAt: string;
  value: number;
  commission: number;
  status: FilterCommissionStatus;
  currency: string;
  brand: string;
  title: string;
  productImage: string;
  shortenedTrackingLink: string;
  clicks: number;
  linkCommission: number;
  conversionRate: number;
  sales: number;
}

export interface ConversionPerformanceResponse {
  summary: ConversionPerformanceSummaryAPI;
  orders: ConversionPerformanceOrdersAPI[];
  pagination: { limit: number; page: number; total_count: number };
}

export interface ConversionPerformance {
  summary: ConversionPerformanceSummary;
  orders: ConversionPerformanceOrders[];
}

export interface ConversionPerformancePaginated {
  conversions: Map<number, ConversionPerformance | null>;
  pagination: {
    totalCount: number;
    limit: number;
    page: number;
  };
}

export interface MarketplaceState {
  isFetching: FetchingMarketplaceState;
  error: string | null;
  favouriteProductsError: string | null;
  linkPerformanceError: string | null;
  conversionPerformanceError: string | null;
  isTrackingLinkFeedbackCompleted: boolean;
  hasTrackingLinkFeedbackForm: boolean | null;
  postTrackingLinkFeedbackOpen: boolean;
  postTrackingLinkFeedbackError: string | null;
  retailers: Retailer[] | null;
  getTrackingLinkError: string | null;
  postTrackingLinkError: string | null;
  productTrackingLinks: Record<string, TrackingLink | null>;
  interests: string[] | null;
  waitlistStatus: WaitlistStatusApiResponse | null;
  favouriteProducts: string[] | null;
  productSearchQuery: string | null;
  productFilters: ProductFilters;
  pagedProducts: {
    products: MarketplaceProduct[][] | null;
    paging: {
      totalHits: number | null;
      totalPages: number | null;
      page: number | null;
      size: number | null;
      isFirstPage: boolean | null;
      isLastPage: boolean | null;
    };
  };
  linkPerformance: LinkPerformancePaginated;
  conversionPerformance: ConversionPerformancePaginated;
  status: 'idle' | 'loading' | 'error';
  aggregations: {
    globalAggregations: GlobalAggregations | Record<string, never>;
    queryAggregations: QueryAggregations | Record<string, never>;
  };
  hasAcceptedMarketplaceTerms: boolean | null;
}

export const defaultMarketplaceState: MarketplaceState = {
  retailers: null,
  waitlistStatus: null,
  isTrackingLinkFeedbackCompleted: false,
  hasTrackingLinkFeedbackForm: null,
  hasAcceptedMarketplaceTerms: null,
  isFetching: {
    retailers: false,
    productTrackingLinks: false,
    interests: false,
    createTrackingLinks: false,
    waitlist: false,
    favouriteProducts: false,
    postTrackingLinkFeedback: false,
    getTrackingLinkFeedbackForm: false,
    products: false,
    marketplaceTerms: false,
    linkPerformance: false,
    conversionPerformance: false,
  },
  error: null,
  linkPerformanceError: null,
  conversionPerformanceError: null,
  favouriteProductsError: null,
  getTrackingLinkError: null,
  postTrackingLinkError: null,
  productTrackingLinks: {},
  favouriteProducts: null,
  interests: null,
  productSearchQuery: null,
  postTrackingLinkFeedbackOpen: false,
  postTrackingLinkFeedbackError: null,
  productFilters: {
    brands: [],
    industries: [],
    partners: [],
    categories: [],
  },
  pagedProducts: {
    products: null,
    paging: {
      totalHits: null,
      totalPages: null,
      page: null,
      size: null,
      isFirstPage: null,
      isLastPage: null,
    },
  },
  linkPerformance: {
    links: new Map<number, [] | null>().set(0, null),
    pagination: {
      totalCount: 0,
      limit: 15,
      page: 0,
      sort: pageSortOptions[0].value as SortOptionKey,
    },
  },
  conversionPerformance: {
    conversions: new Map<number, ConversionPerformance | null>().set(0, null),
    pagination: {
      limit: 15,
      page: 0,
      totalCount: 0,
    },
  },
  status: 'idle',
  aggregations: {
    globalAggregations: {},
    queryAggregations: {},
  },
};

export interface TrackingLink {
  tracking_link: string;
  shortened_tracking_link: string;
}

export interface Retailer {
  id: string;
  name: string;
  website_url: string;
  provider_advertiser_id: string;
}

export const pageLimits = [15, 30, 60];

export interface MarketplaceInterestsBody {
  influencerInterests: string[];
}
export interface MarketplaceAdvertisersBody {
  programmeIds: string[];
}
export interface FavouriteProductsResponse {
  favouriteProducts: string[];
}

export type FavouriteProductsUpdateAction = 'add' | 'remove';

export interface UpdateFavouriteProductsBody {
  action: FavouriteProductsUpdateAction;
}
