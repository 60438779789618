import dayjs from 'dayjs';
import { getInviteCommissionPayload } from '../../store/Invitation/transformer';
import {
  getAssociationCommissionPayload,
  transformItemPayload,
} from '../../store/Programme/payload';
import {
  CampaignAssociation,
  CampaignAssociationApi,
  CampaignDetails,
  CampaignDetailsApi,
  CampaignOverview,
  CampaignOverviewApi,
  CampaignSummary,
  CampaignSummaryApi,
  InfluencerData,
  InfluencerDataAPI,
  InfluencerInvites,
  InfluencerInvitesAPI,
  InfluencerMappingRequest,
  InfluencerMappingRequestAPI,
  InfluencerProgramme,
  InfluencerProgrammeAPI,
  InvitationOverview,
  InvitationOverviewApi,
  InvitationStatus,
  Links,
  LinksApi,
  MappedInfluencer,
  MappedInfluencerAPI,
  ProgrammeAssociation,
  ProgrammeAssociationApi,
  ProgrammeDetails,
  ProgrammeDetailsApi,
  MappingRequestDetails,
  MappingRequestDetailsAPI,
  PermissionAPI,
  PermissionGroups,
  ProgrammeOverview,
  ProgrammeOverviewApi,
  TrackingLinkAPI,
  TrackingLink,
  AgencyDetails,
  AgencyDetailsApi,
  InfluencerCommissionsByPageNumber,
  InfluencerCommission,
  PaginatedInfluencerCommissions,
} from './types';
import {
  CommissionItemAPImodel,
  InfluencerCommissionAPImodel,
  PaginatedInfluencerCommissionsAPImodel,
} from '../common/interfaces';

const transformAssociatedInfluencers = (
  influencers: MappedInfluencerAPI[]
): MappedInfluencer[] => {
  return influencers.map(influencer => ({
    influencerId: influencer.influencer_id,
    influencerFirstName: influencer.influencer_first_name,
    influencerLastName: influencer.influencer_last_name,
    emailAddress: influencer.email_address,
    instagramFollowers: influencer.instagram_followers,
    youtubeSubscribers: influencer.youtube_subscribers,
    instConnected: influencer.inst_connected,
    ytConnected: influencer.yt_connected,
    avatarUrl: influencer.avatar_url,
    associatedAt: influencer.associated_at,
  }));
};

const transformAssociationRequests = (
  influencers: InfluencerMappingRequestAPI[]
): InfluencerMappingRequest[] => {
  return influencers.map(influencer => ({
    influencerId: influencer.influencer_id,
    influencerFirstName: influencer.influencer_first_name,
    influencerLastName: influencer.influencer_last_name,
    emailAddress: influencer.email_address,
    instagramFollowers: influencer.instagram_followers,
    youtubeSubscribers: influencer.youtube_subscribers,
    instConnected: influencer.inst_connected,
    ytConnected: influencer.yt_connected,
    avatarUrl: influencer.avatar_url,
    requestedAt: influencer.requested_at,
  }));
};

const transformMappingRequestDetails = (
  mappingRequest: MappingRequestDetailsAPI
): MappingRequestDetails => {
  return {
    requestId: mappingRequest.request_id,
    agencyId: mappingRequest.agency_id,
    agencyNotified: mappingRequest.agency_notified,
    status: mappingRequest.status,
    permissionGroups: mappingRequest.permission_groups,
  };
};

const transformInfluencerProgrammes = (
  programmes: InfluencerProgrammeAPI[]
): InfluencerProgramme[] => {
  return programmes.map(programme => ({
    programmeId: programme.programme_id,
    influencerId: programme.influencer_id,
    campaignId: programme.campaign_id,
    programmeTitle: programme.programme_title,
  }));
};

const transformInfluencerInvites = (
  invites: InfluencerInvitesAPI[]
): InfluencerInvites[] => {
  return invites.map(invite => ({
    programmeId: invite.programme_id,
    campaignId: invite.campaign_id,
    applicationId: invite.application_id,
    id: invite.id,
    programmeTitle: invite.programme_title,
    createdTimestamp: invite.created_timestamp,
    status: invite.status as InvitationStatus,
    influencerId: invite.influencer_id,
    emailAddress: invite.email_address,
  }));
};

const transformAgencyInfluencerAssociatedProgrammes = (
  programmes: ProgrammeOverviewApi[]
): ProgrammeOverview[] => {
  return programmes.map(programme => ({
    influencerId: programme.influencer_id,
    programmeId: programme.programme_id,
    programmeTitle: programme.programme_title,
    viewCommissions: programme.view_commissions,
    keyCloakId: programme.key_cloak_id,
    influencerDiscountCode: programme.influencer_discount_code,
    invitedDate: programme.invited_date,
    acceptedDate: programme.accepted_date,
    agreementVersionAccepted: programme.agreement_version_accepted,
    commissions: programme.commissions
      ? getAssociationCommissionPayload(programme.commissions, 'programme')
      : null,
    campaignId: programme.campaign_id,
    programmeDescription: programme.programme_description,
    bannerImageId: programme.banner_image_id,
  }));
};

const transformAgencyInfluencerAssociatedCampaigns = (
  campaigns: CampaignOverviewApi[]
): CampaignOverview[] => {
  return campaigns.map(campaign => ({
    name: campaign.name,
    brandName: campaign.brand_name,
    brief: campaign.brief,
    startDate: dayjs(campaign.start_date),
    endDate: dayjs(campaign.end_date),
    timeZone: campaign.time_zone,
    campaignBannerId: campaign.campaign_banner_id,
    programmeId: campaign.programme_id,
    campaignId: campaign.campaign_id,
    isActive: campaign.is_active,
    hasExpired: campaign.has_expired,
    daysToExpiry: campaign.days_to_expiry,
  }));
};

const transformInvitationOverview = (
  invite: InvitationOverviewApi
): InvitationOverview => {
  return {
    id: invite.id,
    programmeId: invite.programme_id,
    influencerId: invite.influencer_id,
    keycloakId: invite.keycloak_id,
    influencerName: invite.influencer_name,
    viewCommissions: invite.view_commissions,
    campaignId: invite.campaign_id,
    applicationId: invite.application_id,
    createdTimestamp: invite.created_timestamp,
    status: invite.status,
    agreement: invite.agreement,
    programmeName: invite.programme_name,
    commissions: invite.commissions
      ? getInviteCommissionPayload(invite.commissions)
      : null,
    emailAddress: invite.email_address,
    campaignSummary: transformCampaignSummary(invite.campaign_summary),
    programmeDescription: invite.programme_description,
    bannerImageId: invite.banner_image_id,
  };
};

const transformAgencyInfluencerAssociatedInvitations = (
  invites: InvitationOverviewApi[]
): InvitationOverview[] => {
  return invites.map(invite => transformInvitationOverview(invite));
};

const transformAgencyInfluencerProgrammeDetails = (
  details: ProgrammeDetailsApi
): ProgrammeDetails => {
  return {
    id: details.id,
    brand: details.brand,
    title: details.title,
    description: details.description,
    state: details.state,
    urls: details.urls,
    commissions: details.commissions
      ? getAssociationCommissionPayload(details.commissions, 'programme')
      : null,
    defaultCurrency: details.default_currency,
    connectors: details.connectors,
    programmeGroupId: details.programme_group_id,
    bannerImageId: details.banner_image_id,
    logoImageId: details.logo_image_id,
    thumbnailImageId: details.thumbnail_image_id,
  };
};

const transformAgencyInfluencerProgrammeAssociation = (
  association: ProgrammeAssociationApi
): ProgrammeAssociation => {
  return {
    influencerId: association.influencer_id,
    programmeId: association.programme_id,
    viewCommissions: association.view_commissions,
    keyCloakId: association.key_cloak_id,
    influencerDiscountCode: association.influencer_discount_code,
    invitedDate: association.invited_date,
    acceptedDate: association.accepted_date,
    agreementVersionAccepted: association.agreement_version_accepted,
    commissions: association.commissions
      ? getAssociationCommissionPayload(association.commissions, 'programme')
      : null,
    campaignId: association.campaign_id,
  };
};

const transformLinks = (links: LinksApi[]): Links[] => {
  return links.map(link => ({
    title: link.title,
    description: link.description,
    destinationUrl: link.destination_url,
  }));
};

const transformAgencyInfluencerCampaignDetails = (
  details: CampaignDetailsApi
): CampaignDetails => {
  return {
    name: details.name,
    brandName: details.brand_name,
    brief: details.brief,
    startDate: dayjs(details.start_date),
    endDate: dayjs(details.end_date),
    timeZone: details.time_zone,
    campaignBannerId: details.campaign_banner_id,
    visualDirection: details.visual_direction,
    connectors: details.connectors,
    links: transformLinks(details.links),
    dos: details.dos,
    donts: details.donts,
    additionalImages: details.additional_images,
    programmeId: details.programme_id,
    campaignId: details.campaign_id,
    isActive: details.is_active,
    hasExpired: details.has_expired,
    daysToExpiry: details.days_to_expiry,
  };
};

const transformAgencyInfluencerCampaignAssociation = (
  association: CampaignAssociationApi
): CampaignAssociation => {
  return {
    name: association.name,
    brandName: association.brand_name,
    brief: association.brief,
    startDate: association.start_date,
    endDate: association.end_date,
    timeZone: association.time_zone,
    campaignBannerId: association.campaign_banner_id,
    programmeId: association.programme_id,
    campaignId: association.campaign_id,
    isActive: association.is_active,
    hasExpired: association.has_expired,
    daysToExpiry: association.days_to_expiry,
    commissions: association.commissions
      ? getAssociationCommissionPayload(association.commissions, 'campaign')
      : null,
  };
};

const transformPermissionGroups = (
  groups: PermissionAPI[]
): PermissionGroups => {
  return groups.reduce((obj, group) => {
    return {
      ...obj,
      [group.id]: {
        groupName: group.name,
        name: group.localisation_keys.name,
        description: group.localisation_keys.description,
      },
    };
  }, {});
};

const transformAssociatedProgrammes = (
  programmes: ProgrammeOverviewApi[]
): ProgrammeOverview[] => {
  return programmes.map(programme => ({
    influencerId: programme.influencer_id,
    programmeId: programme.programme_id,
    programmeTitle: programme.programme_title,
    programmeDescription: programme.programme_description,
    viewCommissions: programme.view_commissions,
    keyCloakId: programme.key_cloak_id,
    influencerDiscountCode: programme.influencer_discount_code,
    invitedDate: programme.invited_date,
    acceptedDate: programme.accepted_date,
    agreementVersionAccepted: programme.agreement_version_accepted,
    campaignId: programme.campaign_id,
    bannerImageId: programme.banner_image_id,
  }));
};

export const transformAssociatedCampaigns = (
  campaigns: CampaignOverviewApi[]
): CampaignOverview[] => {
  return campaigns.map(campaign => ({
    name: campaign.name,
    brandName: campaign.brand_name,
    brief: campaign.brief,
    startDate: dayjs(campaign.start_date),
    endDate: dayjs(campaign.end_date),
    timeZone: campaign.time_zone,
    campaignBannerId: campaign.campaign_banner_id,
    programmeId: campaign.programme_id,
    campaignId: campaign.campaign_id,
    isActive: campaign.is_active,
    hasExpired: campaign.has_expired,
    daysToExpiry: campaign.days_to_expiry,
  }));
};

const transformCampaignSummary = (
  campaignSummary: CampaignSummaryApi | null
): CampaignSummary | null => {
  if (campaignSummary)
    return {
      name: campaignSummary.name,
      startDate: campaignSummary.start_date,
      endDate: campaignSummary.end_date,
      timeZone: campaignSummary.time_zone,
      isActive: campaignSummary.is_active,
      hasExpired: campaignSummary.has_expired,
      campaignBannerId: campaignSummary.campaign_banner_id,
      daysToExpiry: campaignSummary.days_to_expiry,
    };
  return null;
};

const transformInfluencerData = (
  influencer: InfluencerDataAPI
): InfluencerData => {
  return {
    firstName: influencer.influencer_first_name,
    lastName: influencer.influencer_last_name,
  };
};

export const transformAssociatedInvitations = (
  invitations: InvitationOverviewApi[]
): InvitationOverview[] => {
  return invitations.map(invitation => ({
    id: invitation.id,
    programmeId: invitation.programme_id,
    programmeName: invitation.programme_name,
    campaignId: invitation.campaign_id,
    influencerId: invitation.influencer_id,
    keycloakId: invitation.keycloak_id,
    influencerName: invitation.influencer_name,
    viewCommissions: invitation.view_commissions,
    applicationId: invitation.application_id,
    status: invitation.status,
    commissions: invitation.commissions
      ? getAssociationCommissionPayload(
          invitation.commissions,
          invitation.campaign_id ? 'campaign' : 'programme'
        )
      : null,
    createdTimestamp: invitation.created_timestamp,
    emailAddress: invitation.email_address,
    agreement: invitation.agreement,
    campaignSummary: transformCampaignSummary(invitation.campaign_summary),
    programmeDescription: invitation.programme_description,
    bannerImageId: invitation.banner_image_id,
  }));
};

const transformTrackingLink = (trackingLink: TrackingLinkAPI): TrackingLink => {
  return {
    title: trackingLink.title,
    description: trackingLink.description,
    link: trackingLink.link,
    id: trackingLink.id,
    trackingLink: trackingLink.tracking_link,
    campaignId: trackingLink.campaign_id,
    campaignName: trackingLink.campaign_name,
    programmeName: trackingLink.programme_name,
  };
};

const transformTrackingLinks = (
  trackingLinks: TrackingLinkAPI[]
): TrackingLink[] => {
  return trackingLinks.map((trackingLink: TrackingLinkAPI) =>
    transformTrackingLink(trackingLink)
  );
};

const transformAgencyDetails = (
  agencyDetails: AgencyDetailsApi
): AgencyDetails => {
  return {
    id: agencyDetails.id,
    identifierCode: agencyDetails.identifier_code,
    emailAddress: agencyDetails.email_address,
    addressLine1: agencyDetails.address_line_1,
    addressLine2: agencyDetails.address_line_2,
    city: agencyDetails.city,
    postcode: agencyDetails.postcode,
    location: agencyDetails.location,
    countryCode: agencyDetails.country_code,
    countryName: agencyDetails.country_name,
    industries: agencyDetails.industries,
    markets: agencyDetails.markets,
    socialPlatforms: agencyDetails.social_platforms,
    name: agencyDetails.name,
    vatNumber: agencyDetails.vat_number,
    vatRegistered: agencyDetails.vat_registered,
    limitedCompany: agencyDetails.limited_company,
    companyName: agencyDetails.company_name,
    companyNumber: agencyDetails.company_number,
    activeInfluencers: agencyDetails.active_influencers,
  };
};

const transformInfluencerCommission = (
  commission: InfluencerCommissionAPImodel
): InfluencerCommission => {
  return {
    commissionId: commission.commission_id,
    createdAt: commission.created_at,
    status: commission.status,
    customerType: commission.customer_type,
    totalValue: commission.items.reduce((acc, item: CommissionItemAPImodel) => {
      return acc + item.value;
    }, 0),
    totalCommission: commission.items.reduce(
      (acc, item: CommissionItemAPImodel) => {
        return acc + item.commission;
      },
      0
    ),
    items: commission.items.map((item: CommissionItemAPImodel) =>
      transformItemPayload(item)
    ),
    saleSource: commission.sale_source,
    trackingLinkId: commission.tracking_link_id,
    programmeId: commission.programme_id,
    currency: commission.currency,
    programmeTitle: commission.programme_title,
  };
};

interface InfluencerCommissionResponse
  extends PaginatedInfluencerCommissionsAPImodel {
  pageNumber: number;
}

const transformInfluencerProgrammeCommissions = (
  response: InfluencerCommissionResponse,
  currentCommissions: InfluencerCommissionsByPageNumber | undefined
): PaginatedInfluencerCommissions => {
  const newCommissions: InfluencerCommission[] =
    response.commissions && response.commissions.length
      ? response.commissions.map((commission: InfluencerCommissionAPImodel) =>
          transformInfluencerCommission(commission)
        )
      : [];

  const commissionPageMap: InfluencerCommissionsByPageNumber =
    currentCommissions
      ? currentCommissions.set(
          response.pageNumber,
          response.commissions && response.commissions.length
            ? response.commissions.map(
                (commission: InfluencerCommissionAPImodel) =>
                  transformInfluencerCommission(commission)
              )
            : []
        )
      : new Map().set(0, newCommissions);
  return {
    commissions: commissionPageMap,
    pagination: response.pagination
      ? {
          totalCount: response.pagination.total_count,
          count: response.pagination.count,
          offset: response.pagination.offset,
        }
      : { totalCount: 0, count: 0, offset: 0 },
  };
};

export default {
  transformAssociatedInfluencers,
  transformAssociationRequests,
  transformMappingRequestDetails,
  transformInfluencerProgrammes,
  transformInvitationOverview,
  transformInfluencerInvites,
  transformAgencyInfluencerAssociatedProgrammes,
  transformAgencyInfluencerAssociatedCampaigns,
  transformAgencyInfluencerAssociatedInvitations,
  transformAgencyInfluencerProgrammeDetails,
  transformAgencyInfluencerProgrammeAssociation,
  transformAgencyInfluencerCampaignDetails,
  transformAgencyInfluencerCampaignAssociation,
  transformPermissionGroups,
  transformAssociatedProgrammes,
  transformAssociatedCampaigns,
  transformAssociatedInvitations,
  transformInfluencerData,
  transformTrackingLinks,
  transformAgencyDetails,
  transformInfluencerProgrammeCommissions,
  transformLinks,
  transformCampaignSummary,
  transformTrackingLink,
};
