import styled, { css } from 'styled-components';
import { theme } from '../../styling/theme';

export const FormLabel = styled.label<{
  isBold: boolean;
  darkMode?: boolean;
  isTitleLeft?: boolean;
  hasBottomPadding?: boolean;
  shouldHideTitleLabel?: boolean;
}>`
  color: ${props =>
    props.darkMode ? 'white' : theme(props).COLOUR.BACKGROUND_DARKEST};
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  font-weight: ${props => (props.isBold ? '400' : '300')};
  ${props => props.isTitleLeft && `padding-right: ${theme(props).SIZE.SMALL}`}
  ${props =>
    props.hasBottomPadding && `padding-bottom: ${theme(props).SIZE.SMALL}`}
  ${props =>
    props.shouldHideTitleLabel &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
    `}
`;
