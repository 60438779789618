import React from 'react';
import {
  Alignment,
  BackLink,
  Box,
  ErrorMessage,
  FormLabel,
  Logo,
  PrimaryButton,
  Spacer,
  TextInput,
} from '../../../common';
import { useFirstLogin } from './useFirstLogin';
import s from './FirstLogin.styles';
import { getKeycloakInstance } from '../../../../keycloak/keycloak-init';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { agentFirstLoginTranslations } from './translations';

export const FirstLogin: React.FC = () => {
  const { agentFirstLogin: t } = useTranslationsController(
    agentFirstLoginTranslations
  );
  const {
    handleFirstName,
    handleLastName,
    handleDetailsSubmit,
    firstName,
    lastName,
    error,
    isMobile,
  } = useFirstLogin(t);

  const logout = getKeycloakInstance()?.logout;

  return (
    <s.ProfileSetupContainer role={'main'}>
      <s.HeadWrapper isMobile={isMobile}>
        <s.LogoContainer>
          <Logo collapsed={false} />
        </s.LogoContainer>
        <s.BackLink isMobile={isMobile}>
          <BackLink
            redirectUrl={'#'}
            onClick={logout}
            label={t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.LOGOUT']}
            colorVariant={'secondary'}
            testId={'t_logout_button'}
            isWhite
          />
        </s.BackLink>
      </s.HeadWrapper>

      <s.ContentWrapper isMobile={isMobile}>
        <s.Title>{t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.TITLE']}</s.Title>
        <s.Greeting>
          {t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.GREETING_MESSAGE']}
        </s.Greeting>
        <Spacer />
        <s.Form>
          <FormLabel darkMode={true} htmlFor={'first-name'} isBold>
            {t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.FIRST_NAME']}
          </FormLabel>
          <TextInput
            id={'first-name'}
            data-testid={'t_input_first_name'}
            onChange={handleFirstName}
          />
          <Spacer isVertical={true} />
          <FormLabel darkMode={true} htmlFor={'last-name'} isBold>
            {t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.LAST_NAME']}
          </FormLabel>
          <TextInput
            id={'last-name'}
            data-testid={'t_input_last_name'}
            onChange={handleLastName}
          />
          <Spacer isVertical={true} amount={16} />
          <Box flex justify={Alignment.END}>
            <Box flex justify={Alignment.SPACE_BETWEEN}>
              <PrimaryButton
                data-testid="t_confirm_button"
                fullWidth
                onClick={handleDetailsSubmit}
                disabled={!firstName || !lastName}
                aria-disabled={!firstName || !lastName}
              >
                {t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.CONTINUE']}
              </PrimaryButton>
            </Box>
          </Box>
        </s.Form>
        {error && <ErrorMessage message={error} />}
      </s.ContentWrapper>
    </s.ProfileSetupContainer>
  );
};
