import {
  GlobalAggregations,
  GlobalAggregationsApi,
  QueryAggregations,
  QueryAggregationsApi,
} from '../../components/pages/Marketplace/types';
import {
  ConversionPerformance,
  ConversionPerformancePaginated,
  ConversionPerformanceResponse,
  LinkPerformance,
  LinkPerformancePaginated,
  LinkPerformanceResponse,
  Retailer,
  SortOptionKey,
} from './store';
import {
  MarketplaceFeedback,
  MarketplaceFeedbackApi,
  MarketplaceFeedbackFromApi,
} from './types';

export const transformMarketplaceFeedbackToApi = (
  payload: MarketplaceFeedback
): MarketplaceFeedbackApi | null => {
  if (
    payload.easierToFindProductsToPromote &&
    payload.likelyToRecommendConnect
  ) {
    return {
      easier_to_find_products_to_promote: payload.easierToFindProductsToPromote,
      likely_to_recommend: payload.likelyToRecommendConnect,
      improvements_or_changes: payload.improvementsOrChanges,
    };
  }
  return null;
};

export const checkMarketplaceFeedbackFromApi = (
  payload: MarketplaceFeedbackFromApi | null
): boolean => {
  if (payload && payload.created_at) {
    return true;
  }
  return false;
};

export const transformAggregationsFromApi = (
  payload: Record<string, never> | GlobalAggregationsApi | QueryAggregationsApi,
  retailers: Retailer[] | null
): Record<string, never> | GlobalAggregations | QueryAggregations => {
  if (Object.keys(payload).length === 0 || !retailers) {
    return {
      categoryGroupId: {},
      advertiserName: {},
      brands: {},
      merchantCategory: {},
    };
  }
  const aggregations = {
    categoryGroupId: payload.categoryGroupId,
    advertiserName: {},
    brands: {},
    merchantCategory: {},
  };
  const advertiserEntries = Object.entries(payload.advertiserId);
  if (advertiserEntries.length === 0) {
    return aggregations;
  }

  return {
    ...aggregations,
    advertiserName: Object.fromEntries(
      advertiserEntries.map(entry => {
        const retailer = retailers.find(e => entry[0] === e.id);
        return [retailer?.name, entry[1]];
      })
    ),
  };
};

export const transformLinkPerformance = (
  linkPerformanceResponse: LinkPerformanceResponse,
  linksPaginated: Map<number, LinkPerformance[] | null>,
  sort: SortOptionKey
): LinkPerformancePaginated => {
  const page = linkPerformanceResponse.pagination.page;
  const totalCount = linkPerformanceResponse.pagination.total_count;
  const limit = linkPerformanceResponse.pagination.limit;
  const links: LinkPerformance[] = linkPerformanceResponse.links.map(pLink => ({
    id: pLink.id,
    createdAt: pLink.created_at,
    commission: pLink.commission,
    title: pLink.title,
    productImage: pLink.product_image,
    retailer: pLink.retailer,
    brand: pLink.brand,
    shortenedTrackingLink: pLink.shortened_tracking_link,
    clicks: pLink.clicks,
    sales: pLink.sales,
    conversionRate: pLink.conversion_rate,
    currency: pLink.currency,
  }));
  return {
    links: new Map(linksPaginated).set(page, links),
    pagination: {
      totalCount,
      limit,
      page,
      sort,
    },
  };
};

export const transformConversionPerformance = (
  conversionPerformanceResponse: ConversionPerformanceResponse,
  conversionsPaginated: Map<number, ConversionPerformance | null>
): ConversionPerformancePaginated => {
  const page = conversionPerformanceResponse.pagination.page;
  const totalCount = conversionPerformanceResponse.pagination.total_count;
  const limit = conversionPerformanceResponse.pagination.limit;
  const conversions: ConversionPerformance = {
    summary: {
      commissions: conversionPerformanceResponse.summary.commissions.map(e => ({
        amount: e.amount,
        currency: e.currency,
      })),
      sales: conversionPerformanceResponse.summary.sales,
      linkClicks: conversionPerformanceResponse.summary.link_clicks,
      conversionRate: conversionPerformanceResponse.summary.conversion_rate,
    },
    orders: conversionPerformanceResponse.orders.map(order => ({
      id: order.id,
      createdAt: order.created_at,
      value: order.value,
      commission: order.commission,
      status: order.status,
      currency: order.currency,
      brand: order.brand,
      title: order.title,
      productImage: order.product_image,
      shortenedTrackingLink: order.shortened_tracking_link,
      clicks: order.clicks,
      linkCommission: order.link_commission,
      conversionRate: order.conversion_rate,
      sales: order.sales,
      retailer: order.retailer,
    })),
  };
  return {
    conversions: new Map(conversionsPaginated).set(page, conversions),
    pagination: {
      totalCount,
      limit,
      page,
    },
  };
};
