import React, { FC } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Wrapper } from './OptionsBar.styles';

interface OptionsBarProps {
  value?: number;
  onChange?: (e: any, v: any) => void;
  labels: string[];
  disabled?: boolean;
}

export const OptionsBar: FC<OptionsBarProps> = ({
  value = 0,
  onChange,
  labels,
  disabled,
}) => {
  return (
    <Wrapper data-testid="t_options_bar">
      <Tabs value={value} onChange={onChange} centered data-testid="t_options">
        {labels &&
          labels.map((label: string, index: number) => {
            return (
              <Tab
                data-tour={label && `${label.toLowerCase()}_tab`}
                disabled={labels.length < 2 || disabled}
                aria-disabled={labels.length < 2}
                key={label}
                data-testid={`t_options-tab-${index}`}
                label={label}
              />
            );
          })}
      </Tabs>
    </Wrapper>
  );
};
