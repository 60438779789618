import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { Breakpoint } from '../../styling/breakpoints';

export const ScrollableWrapper = styled.div`
  & .MuiTabs-indicator {
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
    height: ${px2rem(3)};
  }
  & .MuiTab-wrapper {
    font-size: ${px2rem(14)};
    padding-left: ${props => theme(props).SIZE.XSMALL};
    padding-right: ${props => theme(props).SIZE.XSMALL};
  }

  & .MuiTabs-scrollButtons {
    position: relative;
    margin-top: 2px;
    background-color: ${props => theme(props).COLOUR.WHITE};
  }

  & .MuiTabScrollButton-root {
    width: 20px;
    opacity: 0.8;
    flex-shrink: 0;

    svg {
      width: 1.4em;
      height: 1.4em;
    }
  }
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};
`;

export const Wrapper = styled.div`
  & .MuiTabs-flexContainer.MuiTabs-centered {
    flex-wrap: 'wrap';
  }
  & .MuiTabs-indicator {
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
    height: ${px2rem(3)};
  }
  & .MuiTab-wrapper {
    font-size: ${px2rem(11)};
  }
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};

  @media screen and (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    & .MuiTabs-indicator {
      display: none;
    }

    & .Mui-selected {
      text-decoration: underline;
    }
  }
`;
