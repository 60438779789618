import React, { FC } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { ScrollableWrapper } from './OptionsBar.styles';

interface ScrollableOptionsBar {
  value?: number;
  onChange?: (e: any, v: any) => void;
  labels: string[];
  disabled?: boolean;
  isMobile: boolean;
}

export const ScrollableOptionsBar: FC<ScrollableOptionsBar> = ({
  value = 0,
  onChange,
  labels,
  disabled,
  isMobile,
}) => {
  return (
    <ScrollableWrapper data-testid="t_scrollable_options_bar">
      <Tabs
        value={value}
        onChange={onChange}
        centered={!isMobile}
        data-testid="t_options"
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons={isMobile ? 'on' : 'off'}
      >
        {labels &&
          labels.map((label: string, index: number) => {
            return (
              <Tab
                data-tour={label && `${label.toLowerCase()}_tab`}
                disabled={labels.length < 2 || disabled}
                aria-disabled={labels.length < 2}
                key={label}
                data-testid={`t_options-tab-${index}`}
                label={label}
              />
            );
          })}
      </Tabs>
    </ScrollableWrapper>
  );
};
