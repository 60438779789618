import React, { FC } from 'react';
import { Wrapper } from './Select.styles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import { FormLabel } from '../Label';
import { FlexContainer } from '../FlexContainer';
import { NativeSelectInputProps } from '@material-ui/core/NativeSelect/NativeSelectInput';

export interface SelectOption {
  key: string;
  value: string | number | string[] | undefined;
}

export interface SelectOptions {
  title?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  value?: string;
  options?: SelectOption[] | [];
  testId?: string;
  theme?: 'dark' | 'default' | 'light';
  disabled?: boolean;
  isAutoWidth?: boolean;
  isFullWidth?: boolean;
  isTitleLeft?: boolean;
  leftAligned?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  inputProps?: NativeSelectInputProps;
  isBold?: boolean;
  shouldHideTitleLabel?: boolean;
}

export const Select: FC<SelectOptions> = ({
  title,
  onChange: changeHandler,
  placeholder,
  value = '',
  options = [],
  testId,
  theme = 'default',
  disabled,
  isFullWidth = false,
  isAutoWidth = true,
  isTitleLeft = false,
  leftAligned = true,
  isReadOnly = false,
  isRequired = false,
  isBold = true,
  inputProps,
  shouldHideTitleLabel,
}) => {
  const isControlledComponent = !inputProps;

  return (
    <Wrapper
      selectTheme={theme}
      hasValue={!!value.length || !isControlledComponent}
      isDisabled={disabled}
      isFullWidth={isFullWidth}
      isAutoWidth={isAutoWidth}
      isReadOnly={isReadOnly}
    >
      <FormControl fullWidth>
        <FlexContainer
          fullWidth
          flexDirection={isTitleLeft ? 'row' : 'column'}
          justifyContent={leftAligned ? 'start' : 'center'}
          alignItems={leftAligned && !isTitleLeft ? 'start' : 'center'}
        >
          <FormLabel
            htmlFor={`${title}-${testId}-select-id`}
            darkMode={theme === 'dark'}
            isTitleLeft={isTitleLeft}
            isBold={isBold}
            shouldHideTitleLabel={shouldHideTitleLabel}
          >
            {title}
          </FormLabel>
          <NativeSelect
            required={isRequired}
            disabled={disabled}
            aria-disabled={disabled}
            data-testid={testId}
            value={isControlledComponent ? value : undefined}
            onChange={changeHandler}
            inputProps={
              {
                name: title,
                id: `${title}-${testId}-select-id`,
                'data-testid': `t_${testId}_select`,
                ...inputProps,
              } as NativeSelectInputProps
            }
          >
            {placeholder && (
              <option aria-disabled aria-label="None" value="" disabled hidden>
                {placeholder}
              </option>
            )}
            {options &&
              options.map((option: SelectOption, i: number) => {
                return (
                  <option key={`${option.value}_${i}`} value={option.value}>
                    {option.key}
                  </option>
                );
              })}
          </NativeSelect>
        </FlexContainer>
      </FormControl>
    </Wrapper>
  );
};
