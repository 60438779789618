import React, { FC } from 'react';
import { DateCell, HiddenInput, DateLabel } from './Calendar.styles';
import { DatesTableProps } from './types';

export const DatesTable: FC<DatesTableProps> = ({
  currentMonth,
  onSelectDate,
  selectedDate,
  weekRowArray,
}) => {
  const content = weekRowArray?.map((week: Date[], i: number) => (
    <tr key={week.toString()}>
      {week.map((day: Date, j) => {
        return (
          <DateCell key={day.toString()}>
            <HiddenInput
              disabled={day.getMonth() !== currentMonth.getUTCMonth()}
              type="radio"
              name="date"
              id={`date_${day.toISOString()}`}
              value={day.toISOString()}
              onClick={e => onSelectDate(new Date(e.currentTarget.value))}
              defaultChecked={
                selectedDate &&
                new Date(selectedDate).getTime() === day.getTime()
                  ? 'checked'
                  : undefined
              }
            />
            <DateLabel
              data-testid={`t_${day.toISOString()}`}
              htmlFor={`date_${day.toISOString()}`}
              id={`t_date_${i}_${j}`}
            >
              <span>{day.getDate()}</span>
            </DateLabel>
          </DateCell>
        );
      })}
    </tr>
  ));

  return <tbody>{content}</tbody>;
};
